import type { AnalyticsProvider } from '~/plugins/firebase'

export function stripTags(data: string | null | undefined): string {
  const element: HTMLElement = document.createElement('div')

  element.innerHTML = data ?? ''

  return element.textContent ?? element.innerText ?? ''
}

export function isTest(): boolean {
  return globalThis.location?.href.includes('/deals/test/') ?? false
}

export function getAllowedOptionQuantities(option: Option): Array<number> {
  //@ts-ignore
  return [
    0,
    ...Array.from(
      option.allowedQuantities
        ? option.allowedQuantities
        : new Array(
            isTest()
              ? 999
              : option.stock !== null
                ? Math.max(0, isTest() ? 999 : option.stock + 1)
                : 999,
          ).keys(),
    ),
  ].filter((v, i, a) => a.indexOf(v) === i)
}

export function isApplePayAvailable(): boolean {
  //@ts-ignore
  return !!globalThis.ApplePaySession
}

export function getUrl(routePath: string, ignoreApp: boolean = false): string {
  const origin =
    !ignoreApp && isApp() ? 'outspot://' : globalThis.location.origin
  return origin + routePath
}

export function getRouteUrl(
  routePath: string,
  ignoreApp: boolean = false,
): string {
  return getUrl(getRoute(routePath), ignoreApp)
}

export function getRoute(routePath: string): string {
  const currentLocale = useCurrentLocale()
  return `/${currentLocale}/${routePath}`
}

export function getDealRoute(deal?: Deal, routePath?: string): string {
  if (!deal) {
    return getRoute('')
  }

  const routePathPrefix = isTest() ? 'deals/test' : 'deals'

  routePath = routePath
    ? `${routePathPrefix}/${deal.slug}/${routePath}`
    : `${routePathPrefix}/${deal.slug}/`

  return getRoute(routePath)
}

export function getDealRouteUrl(deal?: Deal, routePath?: string): string {
  return getUrl(getDealRoute(deal, routePath))
}

export function enableTreeBuyOptions(): boolean {
  const locale = useCurrentLocale()
  if(locale === 'nl' || locale === 'fr') {
    return true
  }
  return false
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function pushToDataLayer(data: any): void {
  try {
    //@ts-ignore
    globalThis.dataLayer = globalThis.dataLayer || []

    // @ts-ignore
    globalThis.dataLayer.push(data)
  } catch (e) {
    console.error(e)
  }
}

export function pushCustomEventToDataLayer(event: string, data?: any): void {
  try {
    pushToDataLayer({
      event: event,
      ...(data ?? {}),
    })
  } catch (e) {
    console.error(e)
  }
}

export function pushEcommerceEventToDataLayer(
  event: string,
  value: number,
  data?: any,
): void {
  try {
    const authentication = useAuthentication()
    const user = authentication.getUser()

    if (isApp()) {
      const analytics = useNuxtApp().$firebaseAnalytics as
        | AnalyticsProvider
        | undefined
      if (analytics?.logEvent) {
        const ecommerce = {
          currency: useGlobalMessage('language.currency'),
          value: value,
          ...(data ?? {}),
        }

        analytics.logEvent(event, { ...ecommerce })
      } else {
        console.warn(
          'Firebase Analytics is not available or something went wrong',
        )
      }
    } else {
      const ecommerce = {
        currency: useGlobalMessage('language.currency'),
        value: value,
        user_properties: {
          first_name: user?.firstName,
          last_name: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          zip: undefined,
          country: undefined,
          region: undefined,
          address: undefined,
          city: undefined,
        },
        ...(data ?? {}),
      }

      pushCustomEventToDataLayer(event, { ecommerce })
    }
  } catch (e) {
    console.error(e)
  }
}

export function parseDate(date: string | null | Date): Date {
  if (
    typeof date === 'string' &&
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)
  ) {
    // Safari likes T instead of space
    date = date.replaceAll('-', '/')
    return new Date(date)
  } else if (typeof date === 'string') {
    return new Date(date)
  }

  if (date instanceof Date) {
    return new Date(date)
  }

  return new Date()
}

export function timeout(delay: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

export function formatPreciseDate(dateObj: string, showTime: boolean): string {
  const now = parseDate(dateObj)
  const i18n = useI18n()
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...(showTime && { hour: 'numeric', minute: 'numeric' }),
  }
  return now.toLocaleString(i18n.t('language.locale'), options)
}

export function isRecurringPaymentMethodIdentifier(
  paymentMethodIdentifier: string,
): boolean {
  return [
    //'bancontact',
    //'belfius',
    'creditcard',
    'creditcard-hosted',
    //'eps',
    //'giropay',
    //'ideal',
    //'kbc',
    //'mybank',
    //'paypal',
    //'sofort',
    'nuvei:default',
  ].includes(paymentMethodIdentifier)
}

export function isRegionUS() {
  return useGlobalMessage('language.region') === 'us'
}

export function isRegionEU() {
  return useGlobalMessage('language.region') === 'eu'
}

export function isLocale(code: string) {
  return useCurrentLocale() === code
}

export function isValidDutchPostalCode(postalCode: string): boolean {
  if (!isLocale('nd')) return true
  const dutchPostalCodeRegex = /^[1-9][0-9]{3}\s?[A-Z]{2}$/i // Matches '1234 AB', '1234ab', '1234 ab', '1234AB', etc.
  return dutchPostalCodeRegex.test(postalCode)
}

export function hasGoogleReviews() {
  // return true because disable trustpilot,
  // to enable trustpilot again use isLocale() function
  // for countries where only google will be shown
  return true
}

export function hasTrustpilotReviews() {
  return !hasGoogleReviews()
}

export function hasInteractiveReviews() {
  return !isLocale('us')
}

export function makeNumberFormat(currency: string): Record<string, unknown> {
  return {
    currencyWithSymbol: {
      style: 'currency',
      currency: currency,
      notation: 'standard',
    },
    currencyWithoutSymbol: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  }
}

export function makeDateTimeFormat(): Record<string, unknown> {
  return {
    short: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  }
}

export function clearSessionAddresses() {
  const session = useSessionStorage()

  for (let id in session.orders) {
    session.orders[id].deliveryAddress = {}
    session.orders[id].invoiceAddress = {}
  }
}
